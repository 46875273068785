import React from 'react'
import Layout from '../../staticcomponents/layout'
import './sitemap.scss'
import SiteMapHeader from './sitemapheader'


export default function SiteMap() {
    return (
        <>
            <Layout>
                <SiteMapHeader />
            </Layout>
        </>
    )
}