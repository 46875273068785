import React from 'react'
import Grid from '@material-ui/core/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'gatsby';
import './sitemapheader.scss'

export default function SiteMapHeader() {

  return (
    <>
      <Grid container className="sitemap-header-cls">
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>


        <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
          <h1 className="links-title">Other Nav Links</h1>

          <Grid className="links-container">

            <h2 className="main-links-heading">Style Guide</h2>
            <List className="sitemap-ul">
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/StyleGuide/'} role="link">
                    <ListItemText className="sitemap-text" primary="Style Guide" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PageNotFound/'} role="link">
                    <ListItemText className="sitemap-text" primary="404 Page" />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>

            <h2 className="main-links-heading">Blogs</h2>
            <List className="sitemap-ul">
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PodcastBlog/'} role="link">
                    <ListItemText className="sitemap-text" primary="Podcast Blog" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Longblog/'} role="link">
                    <ListItemText className="sitemap-text" primary="Long Blog" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Shortblog/'} role="link">
                    <ListItemText className="sitemap-text" primary="Short Blog" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/SubscribeBlog/'} role="link">
                    <ListItemText className="sitemap-text" primary="Subscribe Blog" />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>

            <h2 className="main-links-heading">Content</h2>
            <List className="sitemap-ul">
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/EventsWebinarContent/'} role="link">
                    <ListItemText className="sitemap-text" primary="Events and Webinar Content" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Technology-Content/'} role="link">
                    <ListItemText className="sitemap-text" primary="Technology Content/ Why Cartus Content" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/globalservice/'} role="link">
                    <ListItemText className="sitemap-text" primary="Global Service Content" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/ResearchContent/'} role="link">
                    <ListItemText className="sitemap-text" primary="Research Content" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PodcastContent/'} role="link">
                    <ListItemText className="sitemap-text" primary="Podcast Content" />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>


            <h2 className="main-links-heading">Landing</h2>
            <List className="sitemap-ul">
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/ResearchLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Research Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Global-Landing/'} role="link">
                    <ListItemText className="sitemap-text" primary="Global Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PodcastLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Podcast Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/BlogLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Blog Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/EventsWebinarLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Events and Webinar Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/VideoLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Video Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Technology-Landing/'} role="link">
                    <ListItemText className="sitemap-text" primary="Technology Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/GlobalSearchLanding/'} role="link">
                    <ListItemText className="sitemap-text" primary="Global Search Landing" />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>

            <h2 className="main-links-heading">Others</h2>
            <List className="sitemap-ul">
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/TeamBio/'} role="link">
                    <ListItemText className="sitemap-text" primary="Team Bio" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/why-cartus/'} role="link">
                    <ListItemText className="sitemap-text" primary="Why Cartus" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/contact-us/'} role="link">
                    <ListItemText className="sitemap-text" primary="Contact Us" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/about-us/'} role="link">
                    <ListItemText className="sitemap-text" primary="About Us" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/ResourceHub/'} role="link">
                    <ListItemText className="sitemap-text" primary="Resource Hub" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Campaign/'} role="link">
                    <ListItemText className="sitemap-text" primary="Campaign" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PressRelease/'} role="link">
                    <ListItemText className="sitemap-text" primary="Press Release" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/Careers/'} role="link">
                    <ListItemText className="sitemap-text" primary="Careers" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/TermsOfUse/'} role="link">
                    <ListItemText className="sitemap-text" primary="Terms Of Use" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem className="sitemap-listitems">
                <ListItemButton className="sitemap-listbuttons">
                  <Link className="sitemap-links" to={'/PrivacyNotice/'} role="link">
                    <ListItemText className="sitemap-text" primary="Privacy Notice" />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
    </>
  )
}


